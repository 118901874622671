/**
 *
 * LazyLoad
 * make by phamthainb
 */
import React, { ComponentType, lazy, Suspense } from 'react';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`{
   0 {
     transform: translate(0, 0);
   }
   50% {
     transform: translate(0, 15px);
   }
   100% {
     transform: translate(0, 0);
   }
 }`;

export const SLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  user-select: none;
  z-index: 99;
  background-color: white;

  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #03cb83;
    margin: 0 5px;
  }

  .load-3 .line:nth-last-child(1) {
    animation: ${animation} 0.6s 0.1s linear infinite;
  }
  .load-3 .line:nth-last-child(2) {
    animation: ${animation} 0.6s 0.2s linear infinite;
  }
  .load-3 .line:nth-last-child(3) {
    animation: ${animation} 0.6s 0.3s linear infinite;
  }
`;

export const LoadingScreen = () => {
  return (
    <SLoading>
      <div className="load-3">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </SLoading>
  );
};

const loadable = (
  importFunc: () => Promise<{ default: ComponentType<any> }>,
  { fallback = null } = { fallback: <LoadingScreen /> }
) => {
  const LazyComponent = lazy(importFunc);

  return (props: any) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
