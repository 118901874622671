import Wrapper, { IWrapperProps } from './Wrapper';
import React, { InputHTMLAttributes } from 'react';
import { DefaultText } from './Wrapper';

interface Props {
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  wrapperProps: Omit<IWrapperProps, 'children'>;
  register?: any
}

const TextWithWrapper = ({
  inputProps = {},
  wrapperProps,
  register,
}: Props) => {
  return (
    <Wrapper {...wrapperProps}>
      <DefaultText {...inputProps} ref={register} placeholder=" " />
    </Wrapper>
  );
};

export default TextWithWrapper;
