import { getTopic } from '@api/conference';
import { TopicKind, TopicKindData, VoteKind, VoteStatus } from '@api/types';
import { getVoteStatus } from '@api/vote';
import { RootState } from '@main/App/rootReducer';
import { AppThunk } from '@main/App/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type KindType = TopicKind;
export type VoteStatusType = Partial<
  Record<KindType, Record<string, VoteKind>>
>;
export type TopicState = {
  loading: boolean;
  error: string | null;
  data?: TopicKindData;
  voteStatus?: VoteStatusType | null;
  isTopicOpen: boolean;
};
export type ChangeVoteStatusType = {
  kind: KindType;
  result: Record<string, VoteKind> | undefined;
};

export type ChangeVoteStatusTypeWithTopic = {
  kind: KindType;
  result: VoteKind;
  topic: string;
};

const initialState: TopicState = {
  loading: false,
  error: null,
  isTopicOpen: false,
};
const topicSlices = createSlice({
  name: 'topic',
  initialState: initialState,
  reducers: {
    resetTopic() {
      return initialState;
    },
    loadTopicError(state, { payload }: PayloadAction<string>) {
      state.loading = false;
      state.error = payload;
    },
    loadTopicStart(state) {
      state.loading = true;
    },
    loadTopicSuccess(
      state,
      {
        payload: { topic, votes },
      }: PayloadAction<{ topic: TopicKindData; votes: VoteStatusType | null }>
    ) {
      state.loading = false;
      state.data = topic;
      state.voteStatus = votes;
    },
    changeVoteStatus(
      state,
      { payload: { kind, result } }: PayloadAction<ChangeVoteStatusType>
    ) {
      if (state.voteStatus?.[kind]) {
        state.voteStatus[kind] = result;
      }
    },
    changeVoteStatusWithTopic(
      state,
      {
        payload: { kind, result, topic },
      }: PayloadAction<ChangeVoteStatusTypeWithTopic>
    ) {
      if (state.voteStatus?.[kind]) {
        state.voteStatus[kind] = state.voteStatus[kind] || {};
        state.voteStatus[kind]![topic] = result;
      }
    },
    switchTopicOpenStatus(state, { payload }: PayloadAction<boolean>) {
      state.isTopicOpen = payload;
    },
  },
});

export const {
  loadTopicStart,
  loadTopicSuccess,
  loadTopicError,
  changeVoteStatus,
  changeVoteStatusWithTopic,
  resetTopic,
  switchTopicOpenStatus,
} = topicSlices.actions;

const mapStatus = (votes: VoteKind[], mainId?: string) =>
  votes.reduce((acc, val) => {
    //filter vote theo id login shareholder
    if (mainId && val?.voter === mainId) acc[val.topic_id] = val;
    return acc;
  }, {} as Record<string, VoteKind>);

export const loadTopic =
  <T extends boolean>(loadVote: T, id?: string, mainId?: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(loadTopicStart());
        const topics = await getTopic();
        let votes: { data: VoteStatus | null } = { data: null };
        if (loadVote) {
          if (id) {
            votes = await getVoteStatus({ id });
          }
        }

        if (topics?.data?.data && votes.data?.isVote) {
          // console.log("voteResult", votes);
          const voteStatus: Partial<Record<KindType, Record<string, any>>> = {};
          ['kind1', 'kind2', 'kind3', 'kind4', 'kind6'].forEach((kind) => {
            voteStatus[kind as KindType] = mapStatus(
              votes.data!.isVote?.[kind as keyof TopicKindData],
              mainId
            );
          });
          return dispatch(
            loadTopicSuccess({ topic: topics.data?.data, votes: voteStatus })
          );
        }

        dispatch(loadTopicSuccess({ topic: topics.data?.data, votes: null }));
      } catch (e) {
        if ((e as any)?.status === 404) {
          return;
        }
        dispatch(loadTopicError((e as any)?.message));
      }
    };

export const selectAllTopic = (state: RootState) => [
  ...(state.topic?.data?.kind1 || []),
  ...(state.topic?.data?.kind2 || []),
  ...(state.topic?.data?.kind3 || []),
  ...(state.topic?.data?.kind4 || []),
  ...(state.topic?.data?.kind5 || []),
  ...(state.topic?.data?.kind6 || []),

];

export const selectAllVote = (state: RootState) => state?.topic?.voteStatus;
export const selectTopic = (kind: TopicKind) => (state: RootState) =>
  state.topic.data?.[kind];

export const selectVoteStatus = (kind: TopicKind) => (state: RootState) =>
  state.topic?.voteStatus?.[kind];

export const selectVoteStatusWithTopic =
  (kind: TopicKind, topic: string, extra = false) =>
    (state: RootState) =>
      extra ? undefined : state.topic.voteStatus?.[kind]?.[topic];

export const selectTopicOpenStatus = () => (state: RootState) =>
  state?.topic?.isTopicOpen;

export default topicSlices.reducer;
