import { mixin } from '@utils/styles';
import styled from 'styled-components';

export const SLogin = styled.div`
  font-family: 'rb';

  .login-content {
    display: flex;
    flex-wrap: wrap;
    background: linear-gradient(135deg, #2bd25e 0%, #06c8df 100%);

    .login-left {
      width: 51.823%;
      background: #eaf1fa;
      display: flex;
      padding: 32px;
      min-height: 100vh;
      .login-left_container {
        width: 100%;
        padding: 40px;
        box-sizing: border-box;
        height: calc(100%-64px);
        border: 1px solid #00cb82;
        position: relative;
        .logo {
          width: 139px;
          height: 44px;
          display: flex;
          margin: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .login-main {
          margin: 130px auto 150px;
          width: 460px;
          max-width: 100%;
          .header {
            font-size: 32px;
            font-weight: 700;
            line-height: 64px;
            color: #333b6a;
            text-transform: capitalize;
          }
          .desc {
            color: #718098;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 41px;
          }
          .list-button {
            width: 100%;
            align-items: center;
            display: flex;
            flex-direction: column;
            .login__link {
              display: flex;
              width: 100%;
              height: 56px;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              position: relative;
              margin-bottom: 24px;
              font-size: 16px;
              font-weight: 500;
              line-height: 19px;
              color: #333b6a;
              padding: 0 10px;

              &:hover {
                color: white;
                .img {
                  &.active {
                    opacity: 1;
                  }
                  &.nomal {
                    opacity: 0;
                  }
                }
              }
              .img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                transition: all 0.3s;
                &.active {
                  opacity: 0;
                }
              }
              span {
                position: relative;
                z-index: 1;
                text-align: center;
              }
              &.login-admin {
              }
            }
          }
        }
        .login-footer {
          position: absolute;
          bottom: 20px;
          left: 0;
          width: 100%;
          text-align: center;
          color: #718098;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          // margin-bottom: 20px;
          span {
            color: #00cb82;
          }
        }
      }
    }
    .login-right {
      width: 48.177%;
      color: white;
      position: relative;
      min-height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        .header {
          font-size: 32px;
          font-weight: 400;
          line-height: 44px;
          margin-top: 72px;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 24px;
          .bold {
            font-weight: 900;
          }
        }
        .banner-desc {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
          width: 68.865%;
          margin: 0 auto 95px;
        }
      }
      .banner-img {
        width: 100%;
        display: flex;
        img {
          max-width: 100%;
        }
      }
      &::after,
      &::before {
        display: block;
        position: absolute;
        content: '';
        border-radius: 50%;
        background: #ffffff33;
      }
      &::after {
        width: 73.73%;
        top: -10%;
        left: -40%;
        z-index: 1;
        padding-bottom: 73%;
      }
      &::before {
        width: 35%;
        top: -10%;
        right: -20%;
        z-index: 1;
        padding-bottom: 35%;
      }
    }
  }

  @media only screen and (max-width: 1440px) {
    .login-content {
      .login-left {
        .login-left_container {
          .login-main {
            margin: 70px auto;
          }
        }
      }
      .login-right {
        .top {
          .banner-desc {
            width: 90%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .login-content {
      .login-left {
        width: 100%;
        .login-left_container {
          .login-main {
            padding: 20px;
            margin: 50px auto;
          }
        }
      }
      .login-right {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .login-content {
      .login-left {
        width: 100%;
        padding: 20px;
        .login-left_container {
          padding: 20px 20px;
          padding-bottom: 40px;
          .login-main {
            padding: 0px;
            margin: 30px auto;
            .list-button {
              .login__link {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .login-content {
      .login-left {
        padding: 15px;
        .login-left_container {
          .login-main {
          }
        }
      }
    }
  }

  .form-login {
    .desc {
      color: #718098;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 40px;
    }

    .login-type {
      display: flex;
      align-items: center;
      margin: 0 -20px 10px;
      flex-wrap: wrap;
      &__item {
        margin: 0 20px 10px;
        font-weight: 500;
        .checkbox__text {
          font-family: 'rb';
          &.checked {
            font-family: 'rb-500';
          }
        }
      }
    }

    .content {
      .login-item {
        margin-bottom: 20px;
        width: 100%;
      }

      .login-button {
        width: 100%;
        button {
          width: 100%;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          text-transform: uppercase;

          svg {
            margin-right: 13px;
          }
        }
      }
      .tools {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        color: #718098;

        span {
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          &:hover {
            color: #00cb82;
            svg {
              path {
                fill: #00cb82;
              }
            }
          }
        }

        .go-back {
          font-family: 'rb-500';
          svg {
            margin-right: 12px;
          }
        }

        .forgot-password {
          color: #00cb82;
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
`;

export const SOTPForm = styled.form`
  .login-item {
    margin-bottom: 20px;
    width: 100%;
    &.otp {
      position: relative;
    }

    .otp__show {
      min-width: 72px;
      height: 40px;
      padding: 11px 21px;
      position: absolute;
      top: 8px;
      right: 8px;
      background-color: #dfe9f5;
      text-align: center;
    }

    .custom-input {
      height: 56px;
      border-radius: 4px;
      .icon {
        display: flex;
        align-items: center;
      }
      .input-wrapper {
        padding-top: 23px;
      }
    }
  }

  .otp__handle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    &-not-receive {
      color: #718098;
      font-size: 14px;
      line-height: 21px;
      ${mixin.clickable};
    }
    &-resend {
      ${mixin.clickable};
      font-size: 14px;
      line-height: 21px;
      color: #2f80ed;
      text-decoration: underline;
      font-family: 'rb-500';
    }
  }

  .group-button {
    margin-top: 44px;
    button {
      width: 160px;
      height: 40px;
      font-family: 'rb-500';
    }
  }
`;
