import styled from 'styled-components';

export const StyledHeaderModal = styled.div`
  position: relative;
  padding: 0;
  background: #fff;
`;
export const CloseButton = styled.div`
  position: absolute;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;

  top: 30px;
  right: 36px;
  color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 991px) {
    top: 0;
    right: 0;
    border-radius: unset;
  }
`;

export const StyledBodyModal = styled.div`
  padding-bottom: 55px;
  border: none;
  padding: 20px 20px 0;

  @media only screen and (max-width: 991px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const ModalWrapper = styled.div`
  text-align: center;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
  font-family: 'rb';

  @media only screen and (max-width: 991px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const ModalTitle = styled.div`
  margin: 0;
  line-height: 1.42857143;
  margin-bottom: 62px;

  @media (max-width: 991px) {
      margin-bottom: 20px;
    }

  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #333b6a;
    font-family: 'rb-700';
    text-transform: uppercase;
    padding-top: 0;
    text-align: left;
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
  }

  .subtitle {
    color: #718098;
    margin: 0;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    .strong {
      font-family: 'rb-500';
    }
  }
`;

export const ModalContent = styled.div`
  padding-top: 22px;
`;

export const ModalDescription = styled.p`
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #444c5b;
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
`;

export const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
