import { getShareHolder, getStatusBar } from '@api/conference';
import { Shareholder, StatusBar, VoteKind, Message } from '@api/types';
import { RootState } from '@main/App/rootReducer';
import { AppThunk } from '@main/App/store';
import { logout } from '@main/Login/authSlices';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type KindType = 'kind1' | 'kind2' | 'kind3' | 'kind4' | 'kind6';
export type VoteStatusType = Partial<
  Record<KindType, Record<string, VoteKind>>
>;
export type ShareHolderState = {
  loading: boolean;
  error: string | null;
  info?: Shareholder | null;
  statusbar?: StatusBar;
  newMessage?: Message | null;
};
const initialState: ShareHolderState = {
  loading: false,
  error: null,
  info: null,
  newMessage: null,
};
const shareholderSlices = createSlice({
  name: 'shareholder',
  initialState: initialState,
  reducers: {
    resetShareHolder() {
      return { ...initialState };
    },
    loadShareHolderStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadShareHolderSuccess(state, { payload }: PayloadAction<Shareholder>) {
      state.loading = false;
      state.error = null;
      state.info = payload;
    },
    loadShareHolderError(state, { payload }: PayloadAction<string>) {
      state.loading = false;
      state.error = payload;
    },
    loadStatusBarSuccess(state, { payload }: PayloadAction<StatusBar>) {
      state.statusbar = payload;
    },
    loadNewMessageSuccess(state, { payload }: PayloadAction<Message>) {
      state.newMessage = payload;
    },
    reloadUsedShareNumbers(
      state,
      {
        payload: { selectNumber = 1, topicId },
      }: PayloadAction<{ selectNumber: number | undefined; topicId: string }>
    ) {
      const currentSharesNumber = state.info?.sharesNumber || 1;
      if (state.info?.usedShareNumbers?.[topicId]) {
        state.info.usedShareNumbers[topicId] =
          selectNumber * currentSharesNumber;
      }
    },
  },
});

export const {
  loadShareHolderStart,
  loadShareHolderError,
  reloadUsedShareNumbers,
  loadShareHolderSuccess,
  loadStatusBarSuccess,
  resetShareHolder,
  loadNewMessageSuccess
} = shareholderSlices.actions;

export const loadShareHolderInfo = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loadShareHolderStart());
    const response = await getShareHolder();
    dispatch(loadShareHolderSuccess(response?.data?.shareholder));
  } catch (e) {
    dispatch(loadShareHolderError((e as any)?.message));
    if ((e as any)?.status === 401) {
      dispatch(logout());
    }
  }
};

export const loadStatusBar = (): AppThunk => async (dispatch) => {
  try {
    const response = await getStatusBar();
    dispatch(loadStatusBarSuccess(response?.data?.status));
  } catch (e) {
    console.log('Load statusbar error...');
    if ((e as any)?.status === 401) {
      dispatch(logout());
    }
  }
};

export const loadNewMessage = (newMessage: Message): AppThunk => async (dispatch) => {
  try {
    dispatch(loadNewMessageSuccess(newMessage));
  } catch (e) {
    console.log('Load newMessage error...');
  }
};

export const selectShareHolderInfo = createSelector(
  (state: RootState) => state.shareholder,
  (state) => state.info
);

export const selectShareHolderSharesNumber = createSelector(
  (state: RootState) => state.shareholder,
  (state) => state.info?.sharesNumber
);

export const selectAvailableSharesNumber =
  (topicId: string, selectNumber: number) => (state: RootState) => {
    const usedShareNumber = state.shareholder.info?.usedShareNumbers?.[topicId];
    const currentSharesNumber = state.shareholder.info?.sharesNumber || 0;
    if (
      typeof usedShareNumber === 'number' &&
      typeof currentSharesNumber === 'number'
    ) {
      return currentSharesNumber * selectNumber - usedShareNumber;
    }

    return null;
  };

export const selectShareHolderCheckinStatus = createSelector(
  (state: RootState) => state.shareholder,
  (state) => !!state.info?.isCheckedIn
);

export const selectLoggedShareHolderId = createSelector(
  (state: RootState) => state.shareholder,
  (state) => state.info?._id
);

export const selectShareHolderAuthority = createSelector(
  (state: RootState) => state.shareholder,
  (state) => !!state.info?.isAuthority
);

export const selectShareHolderIsAuthorized = createSelector(
  (state: RootState) => state.shareholder,
  (state) => !!state.info?.isAuthorized
);

export const selectShareHolderExistAuthority = createSelector(
  (state: RootState) => state.shareholder,
  (state) => !!state.info?.existAuthority
);

export const selectShareHolderIsGuest = createSelector(
  (state: RootState) => state.shareholder,
  (state) => !!state.info?.isGuest
);

export const selectShareHolderStatusBar = createSelector(
  (state: RootState) => state.shareholder,
  (state) => state.statusbar
);
export const selectNewMessage = createSelector(
  (state: RootState) => state.shareholder,
  (state) => state.newMessage
);

export default shareholderSlices.reducer;
