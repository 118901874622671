import { color, mixin } from '@utils/styles';
import { createGlobalStyle, keyframes } from 'styled-components';

const scale = keyframes`
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
   transform: scale(0.9);
  }
`;
export default createGlobalStyle`

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.swal2-popup, .swal2-title {
  font-family: 'rb';
}

.selection_bubble_root,
.selection_img_search{
  display: none;
}
*{
  &::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
}

&::-webkit-scrollbar
{
	width: 4px;
}

&::-webkit-scrollbar-thumb
{
	border-radius: 4px;
  width: 4px;
  height: 4px;
  background-color: #718098;
}

}
.rcw-widget-container{

  width: 100%;
  max-width: 352px;
 .rcw-title{
   font-size: 18px;
   padding: 15px 0;
 }
}
.rcw-conversation-container .rcw-close-button{
    right: 0;
    top: 2px;
    width: 26px;
    background: transparent;
}
.rcw-conversation-container .rcw-close {
    width: 15px;
    height: 20px;
}

.rcw-launcher{
      width: 60px;
    display: table;
    margin-left: auto;
  img{
    animation: ${scale} 1s linear infinite;
  }
}
.rcw-messages-container{
    height: 326px;
    max-height: 90vh;
}



@media screen and (max-width: 800px){
  .rcw-conversation-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 64vh;
  }
  .rcw-widget-container {
      width: 90%;
      bottom:100px!important;
      max-height:60vh!important;
      max-width: 352px;
      margin: 0;
      height: auto;
      margin-bottom: 91px;
      margin-right: 10px;
  }
}

 

  body {
    background: linear-gradient(180deg, #DFE9F5 0%, rgba(255, 255, 255, 0) 100%);
    color: #333;
    -webkit-tap-highlight-color: transparent;
    line-height: 1.42857143;
    position: relative;
    min-height: 100vh;
    font-family: 'os';
    overflow-x: hidden!important;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    line-height: inherit;
  }
  *, *:after, *:before, input[type="search"] {
    box-sizing: border-box;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  ul, li, ol, dd, h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
  }
  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  /* Workaround for IE11 focus highlighting for select elements */
  select::-ms-value {
    background: none;
    color: #42413d;
  }
  [role="button"], button, input, select, textarea {
    outline: none;
    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 1;
    }
  }
  [role="button"], button, input, textarea {
    appearance: none;
  }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  select::-ms-expand {
    display: none;
  }
  select option {
    color: ${color.textDarkest};
  }
  p {
    line-height: 1.4285;
  }
  textarea {
    line-height: 1.4285;
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
  }
  body, select {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    touch-action: manipulation;
  }

  ${mixin.placeholderColor(color.textLight)}
  input:disabled {
    cursor: not-allowed;
  }

`;
