import { Alert } from '@components/Alert';
import axios from 'axios';
import { handleError, withAuthorization } from './base';
import { AuthoritySetting, AuthorizingPerson, BaseResponse } from './types';

export type Authority = {
  _id: string;
  name: string;
  phone: string;
  identityNumber: string;
  sharesNumber: number;
  identityIssueAt: string;
  identityIssueOn: Date;
  hotline: string;
  shareholder: string;
  shareholderId: string;
  status: 'accept' | 'deny' | 'pending' | '-';
  conference: string;
  file?: any;
};

export async function getAuthorities({
  pageSize = 100,
  pageNumber = 1,
  identityNumber,
}: {
  identityNumber: string;
  pageSize?: number;
  pageNumber?: number;
}) {
  try {
    const url = `/api/authority`;
    return await axios.get<BaseResponse & { authorities: Authority[] }>(url, {
      data: {
        identityNumber,
      },
      params: {
        pageSize,
        pageNumber,
      },
      headers: withAuthorization(),
    });
  } catch (error) {
    const e = handleError(error);
    e.status = (error as any)?.response?.status;
    throw e;
  }
}

export async function getAuthorityForMe() {
  try {
    const url = `/api/shareholder/authority-for/me`;
    return await axios.get<BaseResponse & { data: AuthorizingPerson[] }>(url, {
      headers: withAuthorization(),
    });
  } catch (error) {
    const e = handleError(error);
    e.status = (error as any)?.response?.status;
    throw e;
  }
}

export type CreateAuthorityItem = {
  name: string;
  phone: string;
  identityNumber: string;
  sharesNumber: number;
  address: string;
  identityIssueAt: string;
  identityIssueOn: Date;
  // representativeShareHolder: string;
  file?: any;
};

export async function createAuthorities({ formData, onSuccess }: any) {
  // for (let [key, value] of formData.entries()) {
  //   console.log(key, ':', value);
  // }
  try {
    const url = `/api/authority/create`;
    return await axios
      .post<any>(url, formData, {
        headers: withAuthorization(),
      })
      .then((res) => {
        Alert({ name: `${res.data.message}`, icon: 'success' });
        onSuccess();
      });
  } catch (error) {
    const e = handleError(error);
    e.status = (error as any)?.response?.status;
    throw e;
  }
}

export async function getAuthoritiesFile({
  listAuthorities,
}: {
  listAuthorities: CreateAuthorityItem;
}) {
  try {
    const url = `/api/authority/download-authority-file`;
    return await axios.post<any>(url, listAuthorities, {
      headers: withAuthorization(),
      responseType: 'blob',
    });
  } catch (error) {
    const e = handleError(error);
    e.status = (error as any)?.response?.status;
    throw e;
  }
}

type SettingResponse = {
  settings: {
    data: AuthoritySetting;
  };
};

export async function getAuthoritySetting() {
  try {
    const url = `/api/authority/setting`;
    return axios.get<SettingResponse>(url, { headers: withAuthorization() });
  } catch (error) {
    throw handleError(error);
  }
}

interface HolderOrAuthority {
  name: string;
  phone: string;
  identityIssueOn: Date;
  identityIssueAt: string;
}
export async function getShareHolderOrAuthority(
  identitiNumber: string
): Promise<HolderOrAuthority | null> {
  try {
    const url = `/api/shareholder/holder-or-auth/${identitiNumber}`;
    const res = await axios.get<HolderOrAuthority>(url, {
      headers: withAuthorization(),
    });
    if (res?.data?.identityIssueOn) {
      res.data.identityIssueOn = new Date(res.data.identityIssueOn);
    }
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
}
