import styled from 'styled-components';

const Text = styled.p<{ hide?: string; shadow?: string }>`
  color: ${(p) => p.color};

  ${(p) => p.shadow && `text-shadow: ${p.shadow}`}
  ${(p) =>
    p.hide &&
    `
    @media only screen and (max-width: ${p.hide}) {
      display: none
    }`}
`;

Text.defaultProps = {
  color: '#fff',
};
export default Text;
