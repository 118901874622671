import React, { useCallback, useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthToken } from '@main/Login/authSlices';
import {
  loadNewMessage,
  selectLoggedShareHolderId,
  selectShareHolderInfo,
} from '@main/ShareHolder/shareholderSlices';
import StateMeetingModal from '@main/App/StateMeetingModal';
import { loadTopic } from '@main/ShareHolder/topicSlices';
import { logout } from '@main/Login/authSlices';
import { selectChosenAuthority } from '@main/Home/Header/headerSlices';
import { loadIsSpeaking } from './globalSlices';

interface SocketRef {
  io?: SocketIOClient.Socket;
  loggedShareHolder?: string;
}

function SocketIO() {
  const token = useSelector(selectAuthToken);
  const socketRef = useRef<SocketRef>({});
  const shareHolderId = useSelector(selectLoggedShareHolderId);
  const [streamUrl, setStreamUrl] = useState('');
  const dispatch = useDispatch();

  const chosenAuthority = useSelector(selectChosenAuthority);
  const info = useSelector(selectShareHolderInfo);
  useEffect(() => {
    socketRef.current.loggedShareHolder = shareHolderId;
  }, [shareHolderId]);

  useEffect(() => {
    const socket = io();
    console.log('socket', socket);
    socket.on('connect', () => {
      console.log('Init socket-io connected');

      if (token) {
        console.log('Trigger login via socket');
        socketRef.current?.io?.emit('userLogin', {
          token: token.slice('Bearer '.length),
        });
      }
    });
    socket.on('logout', (data: any) => dispatch(logout()));

    socket.on(
      'speakOpinion',
      (data: { shareholderId: string; streamUrl: string }) => {
        if (data.shareholderId === socketRef.current?.loggedShareHolder) {
          setStreamUrl(data.streamUrl);
        }
      }
    );
    socket.on('updateTopic', () => {
      if (chosenAuthority && info)
        dispatch(
          loadTopic(
            !!socketRef.current?.loggedShareHolder,
            chosenAuthority?.shareholder?._id,
            info?._id
          )
        );
      else dispatch(loadTopic(false));
    });
    socket.on('newReply', (data: any) => {
      console.log(data);
      dispatch(
        loadNewMessage({
          message: data?.message,
          time: data?.time,
        })
      );
    });

    socketRef.current.io = socket;

    return () => {
      socket.disconnect();
      console.log('Socket disconnected');
    };
  }, [dispatch, token, chosenAuthority, info]);

  const onClose = useCallback(() => {
    setStreamUrl('');
    dispatch(loadIsSpeaking(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StateMeetingModal
      onClose={onClose}
      streamUrl={streamUrl}
      isOpen={!!streamUrl}
    />
  );
}

export default React.memo(SocketIO);
