export const LOCALES = {
  vi: {
    currency: "VND",
    locale: "vi-VN"
  },
  en: {
    currency: "USD",
    locale: "en-US"
  }
}

export type TKeyOfLocale = keyof typeof LOCALES