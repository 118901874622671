import isEqual from 'lodash.isequal';
import {useRef} from 'react';

const useDeepCompareMemoize = <T>(value: T) => {
const valueRef = useRef<T>();

  if (!isEqual(value, valueRef.current)) {
    valueRef.current = value;
  }
  return valueRef.current;
};

export default useDeepCompareMemoize;
