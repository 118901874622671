import { setupAxios } from '@api/base';
import React, { useState } from 'react';
import { ModalProvider } from 'react-modal-hook';
import BaseStyles from './BaseStyles';
import FontStyles from './FontStyles';
import NormalizeStyles from './NormalizeStyles';
import Routes from './Routes';
import SocketIO from '@main/App/SocketIO';
import 'react-datepicker/dist/react-datepicker.css';
import 'video.js/dist/video-js.css';
import { useDispatch, useSelector } from 'react-redux';
import { loadShareHolderInfo } from '@main/ShareHolder/shareholderSlices';
import { selectConferenceInfo, selectRefetch, setConfig } from './globalSlices';
import ToTop from './ToTop';
import { ACCESS_TOKEN } from '@constants/common';
import { conferenceConfig } from '../../api/conference';
import { ThemeProvider } from 'styled-components';

setupAxios();

function App() {
  const dp = useDispatch();

  const [refresh, setRefresh] = useState(0);

  const refetch = useSelector(selectRefetch);
  React.useEffect(() => {
    const handlePaste = (e: any) => {
      setTimeout(() => {
        let value = e.target.value;

        if (value && typeof value == 'string') {
          e.target.value = value.replace(/[\u200B-\u200D\uFEFF]/g, '').trim();
          setRefresh((prev: any) => prev + 1);
        }
      }, 100);
    };
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);
  React.useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      dp(loadShareHolderInfo());
    }
  }, [dp, refetch]);

  const loadConfig = async () => {
    const res = await conferenceConfig();
    dp(setConfig(res));
  };
  React.useEffect(() => {
    loadConfig();
  }, []);
  const conference = useSelector(selectConferenceInfo);

  return (
    <ThemeProvider
      theme={{
        widthLogo: conference?.logoWidth,
        heightLogo: conference?.logoHeight,
      }}
    >
      <ModalProvider>
        <FontStyles />
        <NormalizeStyles />
        <BaseStyles />
        <Routes />
        <SocketIO />
        <ToTop />
      </ModalProvider>
    </ThemeProvider>
  );
}

export default App;
