export interface BaseResponse {
  success: boolean;
  message: string;
}
export interface ConferenceResponse extends BaseResponse {
  conference: WelcomeConference;
}

export interface WelcomeConference {
  conference: Conference;
}

export interface Conference {
  address: Address;
  address_2: Address;
  timeMeeting: TimeMeeting;
  timeMeeting_2: TimeMeeting;
  addressMeeting: Address;
  addressMeeting_2: Address;
  isOpenVoting: boolean;
  livestreamLink: string;
  livestreamLink2: string;
  livestreamLink_2: string;
  statedLink: string;
  isConfirm: boolean;
  _id: string;
  isInvitationDone: boolean;
  mainAdmin: string;
  logoUrl: string;
  bannerUrl: string;
  nameCompany: string;
  nameCompany_2: string;
  stockCode: string;
  businessCode: string;
  totalShareholder: number;
  totalShares: number;
  phone: string;
  fax: string;
  nameMeeting: string;
  nameMeeting_2: string;
  content: string;
  content_2: string;
  preside: string;
  preside_2: string;
  secretary: string;
  secretary_2: string;
  createdAt: Date;
  updatedAt: Date;
  timeSubmitComment: TimeSubmitComment;
  slugNameMeeting: string;
  __v: number;
  joinConditional: string;
  guideLink: string;
  guideLink_2?: string;
  confirmPermissionDate: string;
  urlBanner: null | string;
  urlLogo: null | string;
  hotline: null | string;
  logoWidth?: any;
  logoHeight?: any;
}

export interface Address {
  province: string;
  district: string;
  wards: string;
  details: string;
}
export interface TimeSubmitComment {
  start: string;
  end: string;
  isOpen?: boolean;
}
export interface TimeMeeting {
  start: string;
  end: string;
}

export interface ShareholderResponse extends BaseResponse {
  shareholder: Shareholder;
}

export interface Shareholder {
  otp: Otp;
  code: number;
  name: string;
  identityNumber: string;
  identityAddress: string;
  phone: string;
  address: string;
  email: string;
  sharesNumber: number;
  sharesJoinNumber: number;
  sharesAuthorizedNumber: number;
  stockCode: string;
  isVoted: boolean;
  isReVote: boolean;
  isInternal: boolean;
  joinType: string;
  isAuthority: boolean;
  isAuthorized: boolean;
  isCheckedIn: boolean;
  processorAdmin: null;
  shareholderType: 'domestic' | 'foreign';
  shareholderScale: 'personal' | 'enterprise';
  unableTopics: any[];
  _id: string;
  identityDate: string;
  conference: string;
  __v: number;
  createdAt: Date;
  updatedAt: Date;
  loginKey: string;
  usedShareNumbers: Record<string, number>;
  existAuthority: boolean;
  representativeShareholder?: string;
  isGuest?: boolean;
  acceptAuthorityFor: string[];
}

export interface Otp {
  attempt: number;
  lastGet: Date;
}

export interface SendOpinionResponse extends BaseResponse {
  data: OptionData;
}

export interface OptionData {
  type: string;
  _id: string;
  conference: string;
  data: DataData;
  desc: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface DataData {
  shareholder: Shareholder;
  opinion: string;
  sendBoss: boolean;
}

export interface TopicResponse extends BaseResponse {
  data: TopicKindData;
}

export interface TopicKindData {
  kind1: Topic[];
  kind2: Topic[];
  kind3: Topic[];
  kind4: Topic[];
  kind5: Topic[];
  kind6: Topic[];

}

export interface Topic {
  kind: number;
  unableShareholder: any[];
  order: number;
  rate: number;
  isConfirm: boolean;
  isOpen: boolean;
  _id: string;
  name: string;
  name_2?: string;
  fileUrl: string;
  fileUrl_2?: string;
  conference: string;
  selectNumber?: number;
  selections?: {
    _id: string;
    selectName: string;
    fixId?: string;
  }[];
  createdAt: string | Date;
  updatedAt: string | Date;
}

export interface VoteRequest {
  kind?: number;
  topicId?: string;
  point?: Point | undefined;
  note?: string;
  shareholderId?: string;
}

export type VoteSelection = {
  _id: string;
  fixId?: string;
  selectName: string;
  amount?: number;
  amountPercent?: number;
};
export interface VoteSelectRequest {
  topicId: string;
  kind: number;
  selections: VoteSelection[];
  note?: string;
  shareholderId?: string;
}

export interface VoteStatus extends BaseResponse {
  data: any;
  isVote: VoteData;
}

export interface VoteData {
  kind1: VoteKind[];
  kind2: VoteKind[];
  kind3: VoteKind[];
  kind4: VoteKind[];
  kind5: VoteKind[];
  kind6: VoteKind[];

}

export type Point = 1 | 2 | 3;

export interface VoteKind {
  kind: number;
  point: Point;
  isOldVote: boolean;
  isCalculated: boolean;
  isManual: boolean;
  isConfirm: boolean;
  _id: string;
  shareholder: string;
  conference: string;
  event_name: string;
  sharesNumber: number;
  display: string;
  topic_id: string;
  selections: VoteSelection[];
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  voter?: string;
}

export type AuthoritySetting = {
  address: string;
  address_en: string;
  time: string;
  time_en: string;
  fileUrl: string;
  fileUrl2: string;
};

export type StatusBar = {
  completedRate: number;
  notCompletedTopics: Array<Topic>;
};

export interface Opinion {
  _id: string;
  conference: string;
  createdAt: Date | string;
  data: {
    opinion: string;
    shareholder: Shareholder;
    sendBoss: boolean;
    reply?: ReplyOpinion[];
  };
}

export interface ReplyOpinion {
  message: string;
  time: string;
  user: {
    email: string;
    id: string;
    name: string;
    role: number;
  };
}

export interface OpinionResponse extends BaseResponse {
  data: Opinion[];
}

export type TopicKind = 'kind1' | 'kind2' | 'kind3' | 'kind4' | 'kind5' | 'kind6';

export type AuthorizingPerson = {
  shareholder: Shareholder;
  sharesAuthorizedNumber: number;
};

export enum Role {
  SHAREHOLDER = 'shareholder',
  GUEST = 'guest',
}

export type ConferenceTime = {
  endDate: string;
  startDate: string;
};

export type Message = {
  message: string;
  time: string;
};
