import { getConferenceInfomation } from '@api/conference';
import { AuthoritySetting, Conference, ConferenceTime } from '@api/types';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
import { AppThunk } from './store';
import { getAuthoritySetting } from '@api/authority';

export enum EConfOpenStatus {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  NOT_OPEN = 'NOT_OPEN',
}

export type GlobalState = {
  loading: boolean;
  error: string | null;
  conferenceInfo?: Conference;
  authoritySetting?: AuthoritySetting | null;
  refetch: boolean;
  conferenceTime: ConferenceTime | null;
  isSpeaking: boolean;
  config: any;
  logoWidth?: any;
  logoHeight?: any;
};

const initialState: GlobalState = {
  loading: false,
  error: null,
  authoritySetting: null,
  refetch: false,
  conferenceTime: null,
  isSpeaking: false,
  config: {}
};
const globalSlices = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    loadConferenceStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadConferenceSuccess(state, { payload }: PayloadAction<Conference>) {
      state.loading = false;
      state.error = null;
      state.conferenceInfo = payload;
    },
    loadConferenceError(state, { payload }: PayloadAction<string>) {
      state.loading = false;
      state.error = payload;
    },
    loadConferenceTime(state, { payload }: PayloadAction<ConferenceTime>) {
      state.conferenceTime = payload;
    },
    loadIsSpeaking(state, { payload }: PayloadAction<boolean>) {
      state.isSpeaking = payload;
    },
    setAuthoritySetting(
      state,
      { payload }: PayloadAction<AuthoritySetting | null>
    ) {
      state.authoritySetting = payload;
    },
    setConfig(
      state,
      { payload }: any
    ) {
      state.config = payload;
    },
    onRefetch(state) {
      state.refetch = !state.refetch;
    },
  },
});

export const {
  loadConferenceError,
  loadConferenceSuccess,
  loadConferenceStart,
  setAuthoritySetting,
  loadConferenceTime,
  onRefetch,
  loadIsSpeaking,
  setConfig
} = globalSlices.actions;

export const loadConference = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loadConferenceStart());
    const response = await getConferenceInfomation();
    dispatch(loadConferenceSuccess(response?.data?.conference?.conference));
  } catch (e) {
    dispatch(loadConferenceError((e as any)?.message));
  }
};

export const loadAuthoritySetting = (): AppThunk => async (dispatch) => {
  try {
    const response = await getAuthoritySetting();
    dispatch(setAuthoritySetting(response?.data?.settings?.data));
  } catch (e) {
    setAuthoritySetting(null);
  }
};

export const selectError = createSelector(
  (state: RootState) => state.global,
  (state) => state.error
);

export const selectConferenceInfo = createSelector(
  (state: RootState) => state.global,
  (state) => state.conferenceInfo
);

export const selectConferenceBusinessCode = createSelector(
  (state: RootState) => state.global,
  (state) => state.conferenceInfo?.businessCode
);

export const selectAuthoritySetting = createSelector(
  (state: RootState) => state.global,
  (state) => state.authoritySetting
);

export const selectRefetch = createSelector(
  (state: RootState) => state.global,
  (state) => state.refetch
);

export const selectGlobalLoading = createSelector(
  (state: RootState) => state.global,
  (state) => state.loading
);

export const selectConferenceTime = createSelector(
  (state: RootState) => state.global,
  (state) => state.conferenceTime
);
export const selectIsSpeaking = createSelector(
  (state: RootState) => state.global,
  (state) => state.isSpeaking
);

export default globalSlices.reducer;

export const handleCheckConfTime = (conf: {
  startDate: string;
  endDate: string;
}): EConfOpenStatus => {
  const startDate = new Date(conf.startDate).getTime();
  const endDate = new Date(conf.endDate).getTime();
  const now = new Date().getTime();
  if (startDate > now) {
    return EConfOpenStatus.NOT_OPEN;
  }
  if (endDate < now) {
    return EConfOpenStatus.CLOSE;
  }
  return EConfOpenStatus.OPEN;
};
