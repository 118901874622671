export const ACCESS_TOKEN = 'ACCESS_TOKEN';

export const VALIDATE = {
  REQUIRED: 'validate.required',
  PATTERN: {
    PASSWORD: 'validate.pattern.password',
    PHONE: /((09|03|07|08|05)+([0-9]{8})\b)/,
  }
}

export const SHAREHOLDER_ALL_NAME = 'all'