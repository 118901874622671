import axios from 'axios';
import { handleError, withAuthorization } from './base';
import {
  ConferenceResponse,
  Opinion,
  OpinionResponse,
  SendOpinionResponse,
  ShareholderResponse,
  StatusBar,
  TopicResponse,
} from './types';

export async function getConferenceInfomation() {
  try {
    const url = `/api/conference/${process.env.REACT_APP_STOCK_CODE}`;
    return await axios.get<ConferenceResponse>(url);
  } catch (error) {
    throw handleError(error);
  }
}

export async function getShareHolderInfo(id: string) {
  try {
    const url = `/api/shareholder/vote/status/${id}`;
    return await axios.get<any>(url, {
      headers: withAuthorization(),
    });
  } catch (error) {
    throw handleError(error);
  }
}

export const conferenceConfig = async () => {
  try {
    const url = `/api/conference/config/${process.env.REACT_APP_STOCK_CODE}`;
    const res = await axios.get<any>(url);
    // store.dispatch(setConfig(res?.data?.data));
    return res.data.data;

  } catch (error) {
    throw handleError(error);
  }
};

export const authorizedList = async () => {
  try {
    const url = `/api/authority/authorized-list`;
    const res = await axios.get<any>(url, {
      headers: withAuthorization(),
    });
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const downloadAuthorizedFile = async (data: any) => {
  try {
    const url = `/api/authority/download-authority-file-for-auth-list`;
    const res = await axios.post<any>(url, data, {
      headers: withAuthorization(),
      responseType: 'blob',
    });
    return res;
  }
  catch (error) {
    throw handleError(error);
  }
}

export const createBaseAuthorizedList = async (data: any) => {
  try {
    const url = `/api/authority/create-base-authorized-list`;
    const res = await axios.post<any>(url, data, {
      headers: withAuthorization(),
    });
    return res;
  }
  catch (error) {
    throw handleError(error);
  }
}

export async function getAllVotesByUser() {
  try {
    const url = `/api/vote/voted`;
    return await axios.get<any>(url, {
      headers: withAuthorization(),
    });
  } catch (error) {
    throw handleError(error);
  }
}

export async function getShareHolder() {
  try {
    const url = `/api/shareholder`;
    return await axios.get<ShareholderResponse>(url, {
      headers: withAuthorization(),
    });
  } catch (error) {
    throw handleError(error);
  }
}

export async function sendOpinion(opinion: string) {
  try {
    const url = `/api/shareholder/opinion`;
    return await axios.post<SendOpinionResponse>(
      url,
      { opinion },
      {
        headers: withAuthorization(),
      }
    );
  } catch (error) {
    throw handleError(error);
  }
}

export async function getOpinions() {
  try {
    const url = `/api/shareholder/opinion`;
    return await axios.get<OpinionResponse>(url, {
      headers: withAuthorization(),
    });
  } catch (error) {
    throw handleError(error);
  }
}

export async function getTopic() {
  try {
    const url = `/api/topic/${process.env.REACT_APP_STOCK_CODE}`;
    return await axios.get<TopicResponse>(url, {
      headers: withAuthorization(),
    });
  } catch (error) {
    const e = handleError(error);
    e.status = (error as any)?.response?.status;
    throw e;
  }
}
export async function getListKindTime() {
  try {
    const url = `/api/topic/list-kind-time`;
    return await axios.get(url, {
      headers: withAuthorization(),
    });
  } catch (error) {
    const e = handleError(error);
    e.status = (error as any)?.response?.status;
    throw e;
  }
}

export const getTopicSettingTime = async () => {
  try {
    const url = `/api/conference/time-conference/${process.env.REACT_APP_STOCK_CODE}`;
    return await axios.get(url, {
      headers: withAuthorization(),
    });
  } catch (error) {
    const e = handleError(error);
    e.status = (error as any)?.response?.status;
    throw e;
  }
};

export async function checkin(ids: string[]) {
  try {
    const url = `/api/shareholder/checkin`;
    return await axios.put(url, { ids }, { headers: withAuthorization() });
  } catch (error) {
    const e = handleError(error);
    e.status = (error as any)?.response?.status;
    throw e;
  }
}

export async function getStatusBar() {
  try {
    const url = `/api/shareholder/vote-statusbar`;
    return await axios.get<{ status: StatusBar }>(url, {
      headers: withAuthorization(),
    });
  } catch (error) {
    const e = handleError(error);
    e.status = (error as any)?.response?.status;
    throw e;
  }
}
export async function getOpinionsPublic() {
  try {
    const url = `/api/conference/opinion`;
    return await axios.get<{ opinions: { opinions: Opinion[] } }>(url, {
      headers: withAuthorization(),
    });
  } catch (error) {
    const e = handleError(error);
    e.status = (error as any)?.response?.status;
    throw e;
  }
}
