import { useErrorModal } from '@components/Modal/ErrorModal';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadConference,
  selectConferenceInfo,
  selectError,
  selectGlobalLoading,
  loadConferenceTime,
  EConfOpenStatus,
  handleCheckConfTime,
  selectConferenceTime,
} from './globalSlices';
import { useTranslation } from 'react-i18next';
import { LoadingScreen } from '@components/Loading/Lazy';
import { getTopicSettingTime } from '@api/conference';
import { Alert } from '@components/Alert';
import { useHistory } from 'react-router-dom';

export const GlobalLoad = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();

  const loading = useSelector(selectGlobalLoading);
  const confTime = useSelector(selectConferenceTime);

  useEffect(() => {
    dispatch(loadConference());
  }, [dispatch]);

  const error = useSelector(selectError);
  const [showError, setError] = useErrorModal(t('ĐÃ XẢY RA LỖI'));
  useEffect(() => {
    if (error) {
      setError(t(error));
      showError();
    }
  }, [error, setError, showError, t]);

  const conference = useSelector(selectConferenceInfo);
  useEffect(() => {
    if (conference) {
      document.title = conference.nameMeeting + ' ' + conference.nameCompany;
    }
  }, [conference]);

  useEffect(() => {
    getTopicSettingTime()
      .then((res) => {
        dispatch(loadConferenceTime({ ...res.data?.data?.data }));
      })
      .catch((err) => {
        Alert({ name: t(err?.message || ''), icon: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (confTime) {
      const confStatus = handleCheckConfTime(confTime);
      if (confStatus !== EConfOpenStatus.OPEN) {
        history.replace('/');
      }
    }
  }, [dispatch, confTime, history]);

  if (loading) return <LoadingScreen />;

  return <Fragment />;
};
