import { getAuthorityForMe } from '@api/authority';
import { getShareHolder } from '@api/conference';
import { AuthorizingPerson } from '@api/types';
import { RootState } from '@main/App/rootReducer';
import { AppThunk } from '@main/App/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type HeaderState = {
  loading: boolean;
  authorizingPersons: any[];
  error: string | null;
  chosenAuthority: AuthorizingPerson | null;
  allNotVote: any;
};

const initialState: HeaderState = {
  loading: false,
  error: null,
  authorizingPersons: [],
  chosenAuthority: null,
  allNotVote: {
    kind1: {},
    kind2: {},
    kind3: {},
    kind4: {},
    kind6: {},
  },
};

const headerSlices = createSlice({
  name: 'header',
  initialState: initialState,
  reducers: {
    resetHeader() {
      return initialState;
    },
    loadAuthorizedListStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadAuthorizedListSuccess(
      state,
      { payload }: PayloadAction<AuthorizingPerson[]>
    ) {
      state.authorizingPersons = payload;
      state.error = null;
      state.loading = false;
    },
    loadAuthorizedListError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.loading = false;
    },
    loadChosenAuthority(
      state,
      { payload }: PayloadAction<AuthorizingPerson | null>
    ) {
      state.chosenAuthority = payload;
    },
    loadAllNotVote(state, { payload }: PayloadAction<any>) {
      state.allNotVote = payload;
    },
  },
});

export const {
  loadAuthorizedListError,
  loadAuthorizedListStart,
  loadAuthorizedListSuccess,
  resetHeader,
  loadChosenAuthority,
  loadAllNotVote,
} = headerSlices.actions;

export default headerSlices.reducer;

// api
export const loadAuthorized = (myId: string): AppThunk => async (
  dispatch: any
) => {
  dispatch(loadAuthorizedListStart());
  const response = await getAuthorityForMe().catch((err) => {
    dispatch(loadAuthorizedListError(err?.response?.data?.message));
  });
  const currentShareholderResponse = await getShareHolder();
  if (response instanceof Object) {
    if (currentShareholderResponse?.data?.shareholder && response?.data?.data) {

      const filterResponseByAcceptedAuthorities = response?.data.data.filter(
        (a) => a.shareholder.acceptAuthorityFor.includes(myId)
      );

      const chosenAuthority = {
        shareholder: currentShareholderResponse?.data?.shareholder,
        sharesAuthorizedNumber:
          currentShareholderResponse?.data?.shareholder?.sharesNumber,
      };
      //neu co dong ngoai he thong => khong them vao select
      if (currentShareholderResponse?.data?.shareholder?.sharesNumber > 0) {
        dispatch(
          loadAuthorizedListSuccess([
            { ...chosenAuthority },
            ...filterResponseByAcceptedAuthorities,
          ])
        );
      } else {
        dispatch(
          loadAuthorizedListSuccess([
            // { ...chosenAuthority },
            ...filterResponseByAcceptedAuthorities,
          ])
        );
      }

      dispatch(loadChosenAuthority(chosenAuthority));
    }
  }
};

export const selectHeaderLoading = (state: RootState) => state.header.loading;
export const selectHeaderError = (state: RootState) => state.header.error;
export const selectHeaderAuthorities = (state: RootState) =>
  state.header.authorizingPersons;
export const selectChosenAuthority = (state: RootState) =>
  state.header.chosenAuthority;
export const selectAllNotVote = (state: RootState) => state.header.allNotVote;
