import { Modal } from '@components/Modal';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ModalTitle, ModalWrapper } from '@components/ModalContent';
import { useTranslation } from 'react-i18next';
import { loadIsSpeaking } from './globalSlices';
import { useDispatch } from 'react-redux';
const BoxContainer = styled.div`
  padding-top: 25px;
  text-align: left;
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  font-size: 16px;
`;

const StateLink = styled.a`
  background-color: #dfe5ff;
  width: 100%;
  display: inline-block;
  padding: 10px;
  text-align: center;
  color: #4a72d5;
  word-break: break-all;
`;

const SIframe = styled.iframe`
  width: 100%;
  overflow: visible;
  margin-top: 25px;
`;

interface Props {
  streamUrl: string;
  isOpen: boolean;
  onClose: () => void;
}

export const StateMeetingModal = ({ onClose, streamUrl, isOpen }: Props) => {
  const { t } = useTranslation();
  const [modalSpeak, toggleModalSpeak] = useState(false);
  const dispatch = useDispatch();

  if (modalSpeak)
    return (
      <Modal
        onClose={() => {
          toggleModalSpeak(false);
          onClose();
        }}
        isOpen={isOpen}
        width={1100}
        withCloseIcon={true}
        renderContent={({ close }) => (
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <SIframe
            style={{
              minHeight: 500,
              width: '100%',
              height: '100%',
              maxWidth: '100%',
            }}
            src={formatUrl(streamUrl)}
            allow={`camera; microphone`}
            scrolling="no"
            frameBorder="0"
          ></SIframe>
        )}
      />
    );

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      width={700}
      withCloseIcon={true}
      renderContent={({ close }) => (
        <ModalWrapper>
          <ModalTitle>
            <h3>{t('Phát biểu ý kiến')}</h3>
          </ModalTitle>
          <BoxContainer>
            <p>{t('Click vào link dưới để phát biểu ý kiến')}</p>
            <StateLink
              onClick={() => {
                toggleModalSpeak(true);
                dispatch(loadIsSpeaking(true));
              }}
            >
              {streamUrl}
            </StateLink>
          </BoxContainer>
        </ModalWrapper>
      )}
    />
  );
};

export default StateMeetingModal;

const formatUrl = (url: string) => {
  if (url.toLowerCase().indexOf('?code') > -1) {
    return url;
  } else {
    let code = url.split('/')[url.split('/').length - 1];
    console.log(code);
    return 'https://www.vmixcall.com/?Code=' + code;
  }
};
