import { loginByQR } from '@api/login';
import { ACCESS_TOKEN } from '@constants/common';
import { onRefetch } from '@main/App/globalSlices';
import { loginSuccess } from '@main/Login/authSlices';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { handleResetSessionStore } from '../Login/Form';

export const QRcode = () => {
  const dispatch = useDispatch();
  const getApiQr = async (data: any) => {
    const res = await loginByQR(data);
    if (res.data.success === true) {
      localStorage.setItem(ACCESS_TOKEN, res.data.token);
    }
    dispatch(loginSuccess(res.data));
    dispatch(onRefetch());
    handleResetSessionStore();
  };
  const params: any = useParams();
  const data = {
    identityNumber: params?.cccd,
    stockCode: params?.stockCode,
    qrcodeKey: params?.hash,
  };
  useEffect(() => {
    console.log('data', data);
    if (data.identityNumber && data.qrcodeKey && data.stockCode) getApiQr(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};
