import axios from 'axios';
import { handleError, withAuthorization } from './base';

interface BaseLoginRequest {
  identityNumber: string;
}

interface LoginOtpRequest extends BaseLoginRequest {
  otp: string;
}

interface LoginPasswordRequest extends BaseLoginRequest {
  password: string;
  isGuest: boolean;
}

interface LoginByQRRequest extends BaseLoginRequest {
  identityNumber: string;
  stockCode: string,
  qrcodeKey: string
}

export type LoginType = 'otp' | 'password';
export type LoginRequest<LoginType> = LoginType extends 'otp'
  ? LoginOtpRequest
  : LoginPasswordRequest;

export interface OptRequest extends BaseLoginRequest { }

export interface LoginResult {
  success: boolean;
  token: string;
  errorCode?: string;
  message?: string;
  shareholderScale?: string;
}

export async function loginByOtp(payload: LoginOtpRequest) {
  const url = `/api/auth/login`;
  return await axios.post<LoginResult>(url, {
    ...payload,
    stockCode: process.env.REACT_APP_STOCK_CODE,
  });
}

export async function loginByPassword(payload: LoginPasswordRequest) {
  const url = `/api/auth/login/v2`;
  return await axios.post<LoginResult>(url, {
    ...payload,
    stockCode: process.env.REACT_APP_STOCK_CODE,
  });
}

export async function loginByQR(payload: LoginByQRRequest) {
  const url = `/api/auth/login-by-qr`;
  return await axios.post<LoginResult>(url, {
    ...payload,
    stockCode: process.env.REACT_APP_STOCK_CODE,
  });
}

export async function callLogin<T extends LoginType>(
  type: T,
  payload: LoginRequest<T>,
) {
  try {
    if (type === 'otp') {
      return await loginByOtp(payload as LoginOtpRequest);
    }

    return await loginByPassword(payload as LoginPasswordRequest);
  } catch (error) {
    throw handleError(error);
  }
}

export async function getOtp(payload: OptRequest) {
  const url = `/api/auth/get-otp`;
  try {
    return await axios.post(url, {
      stockCode: process.env.REACT_APP_STOCK_CODE,
      ...payload,
    });
  } catch (error) {
    throw handleError(error);
  }
}
export async function getOtpEnterprise(payload: any) {
  const url = `/api/auth/enterprise/get-otp`;
  try {
    return await axios.post(url, {
      ...payload,
    }, { headers: withAuthorization() });
  } catch (error: any) {
    throw error;
  }
}
export async function requestWithOTP(params: any) {
  const url = '/api/shareholder/update-representative';
  try {
    return await axios.put(url, { ...params }, { headers: withAuthorization() });
  } catch (error: any) {
    throw error;
  }

}