import React from 'react';
import styled from 'styled-components';
import { BiArrowToTop } from 'react-icons/bi';
import { mixin } from '@utils/styles';

const onScrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export default function ToTop() {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const refCurrent = ref.current;
    const listener = () => {
      if (document.documentElement.scrollTop > 160) {
        refCurrent?.classList.add('scrolled');
      } else {
        refCurrent?.classList.remove('scrolled');
      }
    };
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, []);

  return (
    <SToTop onClick={onScrollTop} ref={ref}>
      <BiArrowToTop size={20} color="white" />
    </SToTop>
  );
}

export const SToTop = styled.div`
  position: fixed;
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #00cb82;
  z-index: 10;
  cursor: pointer;
  ${mixin.flexCenter};
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.25);
  bottom: -40px;
  transition: 0.2s;
  &.scrolled {
    bottom: 20px;
  }
`;
