import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import styled from 'styled-components';
import { ErrorMessage } from '@hookform/error-message';

interface Props {
  errors: DeepMap<FieldValues, FieldError>;
  name: string;
  as?:
    | undefined
    | React.ReactElement
    | React.ComponentType<any>
    | keyof JSX.IntrinsicElements;
}

export const SErrorText = styled.p`
  text-align: right;
  font-size: 12px;
  color: #ff4b4b;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 0;
`;

const ErrMessage = (props: Props) => {
  return (
    <ErrorMessage
      {...props}
      render={({ message }) => <SErrorText>{message}</SErrorText>}
    />
  );
};

export default ErrMessage;
