import React, { ReactNode } from 'react';
// import { FaCaretDown, FaTimes } from 'react-icons/fa';
import styled, { css } from 'styled-components';

export interface IWrapperProps {
  children: ReactNode;
  icon?: ReactNode;
  label: string;
  rightIcon?: ReactNode;
  isLabelOnTop?: boolean;
  labelLow?: string;
  labelHigh?: string;
  handleRightIconClick?: () => void;
  className?: string;
  onFocus?: () => void;
  required?: boolean;
}

export const DefaultText = styled.input`
  border: none;
  background-color: white;
  padding: 0;
  color: #333b6a;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  padding-right: 10px;
  height: 20px;
  border-radius: 0;
  text-overflow: ellipsis;
`;

export default function Wrapper({
  children,
  icon = null,
  label,
  rightIcon = null,
  isLabelOnTop = false,
  labelLow = '13px',
  labelHigh = '3px',
  handleRightIconClick = () => {},
  className = '',
  onFocus = () => {},
  required = false,
}: IWrapperProps) {
  return (
    <SInputWrapper
      onClick={onFocus}
      className={className}
      isLabelOnTop={isLabelOnTop}
      labelLow={labelLow}
      labelHigh={labelHigh}
    >
      {icon && <div className="icon"> {icon} </div>}{' '}
      <div className="input-wrapper">
        {children}
        <span className="label">
          {label}
          {required && (
            <span style={{ color: '#FF6C6C', marginLeft: 4 }}>*</span>
          )}
        </span>
      </div>
      {rightIcon && (
        <div className="icon pointer" onClick={handleRightIconClick}>
          {' '}
          {rightIcon}{' '}
        </div>
      )}{' '}
    </SInputWrapper>
  );
}

export const SInputWrapper = styled.label<Partial<IWrapperProps>>`
  border: 1px solid #dfe9f5;
  background-color: white;
  transition: 0.2s;
  display: flex;
  min-height: 40px;
  padding-left: 16px;
  min-width: 200px;
  width: 100%;

  &:focus-within {
    border-color: #00cb82;
    .icon {
      svg {
        path {
          fill: #00cb82;
        }
      }
    }
    ${(props) =>
      !props.isLabelOnTop &&
      css`
        .input-wrapper {
          .label {
            font-size: 10px;
            top: ${props.labelHigh};
          }
        }
      `}
  }

  .icon {
    display: flex;
    align-items: center;
    padding-right: 12px;
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: #9ab1cc;
      }
    }
  }
  .input-wrapper {
    position: relative;
    padding-bottom: 4px;
    padding-top: 15px;
    flex-grow: 1;
    .label {
      transition: 0.2s;
      position: absolute;
      top: ${(props) =>
        props.isLabelOnTop ? props.labelHigh : props.labelLow};
      left: 0;
      z-index: 0;
      color: #718098;
      font-size: ${(props) => (props.isLabelOnTop ? '10px' : '12px')};
      line-height: 18px;
    }
    ${DefaultText}:not(:placeholder-shown),  textarea:not(:placeholder-shown) {
      &:not(:focus) ~ .label {
        font-size: 10px;
        top: ${(props) => props.labelHigh};
      }
    }
  }

  .react-datepicker-wrapper {
    height: 20px;
    .react-datepicker__input-container {
      input {
        height: 20px;
        border: none;
        box-shadow: none;
        padding-left: 0;
        color: #333b6a;
        font-size: 14px !important;
        line-height: 16px;
      }
    }
  }

  .react-time-picker__wrapper {
    height: 20px;
    border: none;
    .react-time-picker__inputGroup {
      height: 20px;
      padding-left: 0;
      &__minute,
      &__hour {
        color: #333b6a;
        font-size: 14px !important;
        line-height: 16px;
      }
    }
  }
`;
