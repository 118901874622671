import { Fragment, FunctionComponent } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import styled, { keyframes } from 'styled-components';

export const UnrotateSpinner: FunctionComponent<{
  size?: number;
  color?: string;
  active?: boolean;
}> = ({ active, ...rest }) => (
  <Fragment>{active && <AiOutlineLoading3Quarters {...rest} />}</Fragment>
);

UnrotateSpinner.defaultProps = {
  color: '#718098',
  size: 20,
  active: true,
};

const spin = keyframes`
  from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
  }
`;

const Spinner = styled(UnrotateSpinner)`
  animation: ${spin} 2s linear infinite;
  margin-left: 5px;
`;

export default Spinner;
