import {RefObject, useEffect, useRef} from 'react';
import useDeepCompareMemoize from './deepCompareMomoize';

const useOnOutsideClick = (
  $ignoredElementRefs: RefObject<HTMLElement>,
  isListening: boolean,
  onOutsideClick: () => void,
  $listeningElementRef: RefObject<HTMLElement>
) => {
  const $mouseDownTargetRef = useRef<EventTarget>();
  const $ignoredElementRefsMemoized = useDeepCompareMemoize(
    [$ignoredElementRefs].flat()
  );

  useEffect(() => {
    const handleMouseDown = (event: Event) => {
      $mouseDownTargetRef.current = event.target!;
    };

    const handleMouseUp = (event: Event) => {
      const isAnyIgnoredElementAncestorOfTarget = $ignoredElementRefsMemoized!.some(
        ($elementRef) =>
          $elementRef?.current?.contains($mouseDownTargetRef.current as Node) ||
          $elementRef?.current?.contains(event.target as Node)
      );
      if ((event as any).button === 0 && !isAnyIgnoredElementAncestorOfTarget) {
        onOutsideClick();
      }
    };

    const $listeningElement = ($listeningElementRef || {}).current || document;

    if (isListening) {
      $listeningElement.addEventListener('mousedown', handleMouseDown);
      $listeningElement.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      $listeningElement.removeEventListener('mousedown', handleMouseDown);
      $listeningElement.removeEventListener('mouseup', handleMouseUp);
    };
  }, [
    $ignoredElementRefsMemoized,
    $listeningElementRef,
    isListening,
    onOutsideClick,
  ]);
};

export default useOnOutsideClick;
