import Digital from '@assets/fonts/digital-7 (mono italic).ttf';
import OsRegular from '@assets/fonts/Oswald-Regular.ttf';
import OsMedium from '@assets/fonts/Oswald-Medium.ttf';
import OsLight from '@assets/fonts/Oswald-Light.ttf';
import OsBold from '@assets/fonts/Oswald-Bold.ttf';

import RbRegular from '@assets/fonts/Roboto-Regular.ttf';
import RbMedium from '@assets/fonts/Roboto-Medium.ttf';
import RbLight from '@assets/fonts/Roboto-Thin.ttf';
import RbBold from '@assets/fonts/Roboto-Bold.ttf';

import AvoBold from '../../assets/fonts/UTM AvoBold.ttf';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

    @font-face {
      font-family: os;
      src: url(${OsRegular});
    }
    @font-face {
      font-family: os-500;
      src: url(${OsMedium});
    }
    @font-face {
      font-family: os-700;
      src: url(${OsBold});
    }
    @font-face {
      font-family: os-300;
      src: url(${OsLight});
    }

    @font-face {
      font-family: rb;
      src: url(${RbRegular});
    }
    @font-face {
      font-family: rb-500;
      src: url(${RbMedium});
    }
    @font-face {
      font-family: rb-700;
      src: url(${RbBold});
    }
    @font-face {
      font-family: rb-300;
      src: url(${RbLight});
    }

    @font-face {
      font-family: digital;
      src: url(${Digital});
    }
    @font-face {
      font-family: AvoBold;
      src: url(${AvoBold});
    }
`;
