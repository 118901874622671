export function delay<T extends (...args: any[]) => any>(
  ms: number,
  call: T,
  ...args: any[]
): Promise<ReturnType<T>> {
  return new Promise((resolve, reject) =>
    setTimeout(
      () =>
        call(...args)
          .then(resolve)
          .catch(reject),
      ms
    )
  );
}
