import { ACCESS_TOKEN } from '@constants/common';
import { selectLoggedIn } from '@main/Login/authSlices';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import loadable from '@components/Loading/Lazy';
import { GlobalLoad } from './Global';
import { QRcode } from '../qrcode/index';

const Home = loadable(() => import('@main/Home'));
const Checkin = loadable(() => import('@main/Checkin'));
const Login = loadable(() => import('@main/Login'));
const ShareHolder = loadable(() => import('@main/ShareHolder'));

const Routes = () => {
  return (
    <Router>
      <GlobalLoad />
      <Route exact path="/" component={Home} />
      <Route path="/login/:role" component={Login} />
      <ProtectedRoute exact path="/co-dong" component={ShareHolder} />
      <ProtectedRoute exact path="/checkin" component={Checkin} />
      <Route path="/:stockCode/:cccd/:hash" component={QRcode} />
      <Route path="*" component={QRcode} />
    </Router>
  );
};

export default Routes;

const ProtectedRoute = (props: RouteProps) => {
  const loggedIn = useSelector(selectLoggedIn);
  if (!loggedIn) {
    localStorage.removeItem(ACCESS_TOKEN);

    return <Redirect to="/" />;
  }
  return <Route {...props} />;
};
