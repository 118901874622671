import Color from 'color';
import { css } from 'styled-components';

export const color = {
  primary: '#00CB82', // Green
  secondary: '#1cb35c', // Orange
  textDanger: '#dc3545',
  bgDark: '#3f475e',
  bgDarker: '#343e55',
  bgLight: '#e6ebf7',
  bgLighter: '#F7F9FF',
  backgroundMedium: '#dfe1e6',
  backgroundLight: '#ebecf0',

  textChinh: '#333B6A',

  textDarkest: '#1d407d',
  cam: '#1cb35c',
  textDark: '#3c445b',
  textMedium: '#637093',
  textLight: '#8993a4',
  textLink: '#0052cc',
};

export const sizes = {
  appNavBarLeftWidth: 64,
  secondarySideBarWidth: 230,
  minViewportWidth: 1000,
  margin1: 29,
};

export const zIndexValues = {
  modal: 1000,
  dropdown: 101,
  navLeft: 100,
};

export const font = {
  regular: 'font-family: "os";',
  medium: 'font-family: "os-500";',
  bold: 'font-family: "os-700"; font-weight: 700;',
  size: (size = 16) => `font-size: ${size}px;`,
};

export const mixin = {
  darken: (colorValue: string, amount: number) =>
    Color(colorValue).darken(amount).string(),
  lighten: (colorValue: any, amount: any) =>
    Color(colorValue).lighten(amount).string(),
  rgba: (colorValue: any, opacity: any) =>
    Color(colorValue).alpha(opacity).string(),
  boxShadowMedium: css`
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  `,
  boxShadowDropdown: css`
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.31) 0px 0px 1px;
  `,
  truncateText: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  clickable: css`
    cursor: pointer;
    user-select: none;
  `,
  hardwareAccelerate: css`
    transform: translateZ(0);
  `,
  cover: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,
  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  placeholderColor: (colorValue: string) => css`
    ::-webkit-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    ::-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-ms-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
  `,
  scrollableY: css`
    overflow-x: hidden;
    overflow-y: auto;
  `,
  customScrollbar: ({
    width = 5,
    height = 5,
    borderRadius = 0,
    background = 'transparent',
    thumbBg = '#718098',
  } = {}) => css`
    &::-webkit-scrollbar {
      width: ${width}px;
      height: ${width}px;
    }
    &::-webkit-scrollbar-track {
      background: ${background};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: ${borderRadius}px;
      background: ${thumbBg};
    }
  `,
  backgroundImage: (imageURL: string) => css`
    background-image: url('${imageURL}');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: ${color.backgroundLight};
  `,
  link: (colorValue = color.textLink) => css`
    cursor: pointer;
    color: ${colorValue};
    ${font.medium}
    &:hover, &:visited, &:active {
      color: ${colorValue};
    }
    &:hover {
      text-decoration: underline;
    }
  `,
  tag: (
    background = color.backgroundMedium,
    colorValue = color.textDarkest
  ) => css`
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 0 8px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    color: ${colorValue};
    background: ${background};
    ${font.bold}
    ${font.size(12)}
    i {
      margin-left: 4px;
    }
  `,
  textOverflow: (line: number) => css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${line};
    -webkit-box-orient: vertical;
  `,
};
