import { ACCESS_TOKEN } from '@constants/common';
import axios from 'axios';

export function setupAxios() {
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  // Add a request interceptor
  axios.interceptors.request.use(function (config) {
    return config;
  });

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    return response;
  });
}

export class HttpException extends Error {
  constructor(message: string, public status: number, cause?: Error) {
    super(message);
    this.stack = cause?.stack;
    this.name = 'HttpException';
    this.status = status;
  }
}

export function handleError(error: any) {
  if (error.response) {
    if (
      typeof error.response?.data?.success === 'boolean' &&
      error.response?.data?.message
    ) {
      return new Error(error.response?.data?.message);
    }

    if (error.response?.status >= 500) {
      return new Error('Lỗi hệ thống');
    }

    if (error.response?.status === 401) {
      return new HttpException(
        'Đã kết thúc phiên làm việc. Vui lòng đăng nhập lại để tiếp tục.',
        401
      );
    }

    if (error.response?.status === 404) {
      return new Error('Không tìm thấy tài nguyên');
    }
    if (typeof error.response.data === 'string') {
      return new Error(error.response.data);
    }

    return error.response.data;
  } else if (error.request) {
    return new Error('Không thể kết nối với máy chủ. Vui lòng thử lại sau.');
  } else {
    return error;
  }
}

export function withAuthorization() {
  const token = localStorage.getItem(ACCESS_TOKEN);
  return {
    Authorization: token,
  };
}
