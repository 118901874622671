import Text from '@components/Text';
import { color } from '@utils/styles';
import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useConfirmModal } from './ConfirmModal';
import { useTranslation } from 'react-i18next';
import { FaExclamationTriangle } from 'react-icons/fa';

type UseErrorModalType = [
  () => void,
  React.Dispatch<React.SetStateAction<ReactNode | null>>
];

const IconWrapper = styled.div`
  color: ${color.textDanger};
  margin-bottom: 15px;
`;

export const useErrorModal = (
  title: string,
  renderExtra?: (() => ReactNode) | null,
  initMessage?: string
): UseErrorModalType => {
  const { t } = useTranslation();
  const [error, setError] = useState<ReactNode | null>(initMessage);
  const errorMessage = useMemo(
    () => (
      <Fragment>
        <IconWrapper>
          <FaExclamationTriangle size={80} />
        </IconWrapper>
        {typeof error === 'string' ? (
          <Text as="span" color={color.textDark}>
            {t(error)}
          </Text>
        ) : (
          error
        )}
        {renderExtra?.()}
      </Fragment>
    ),
    [error, renderExtra, t]
  );
  const [showErrorModal] = useConfirmModal({
    title,
    desc: errorMessage,
  });

  return [showErrorModal, setError];
};
