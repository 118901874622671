import { callLogin, LoginRequest, LoginResult, LoginType } from '@api/login';
import { ACCESS_TOKEN } from '@constants/common';
import { onRefetch } from '@main/App/globalSlices';
import { RootState } from '@main/App/rootReducer';
import { AppThunk } from '@main/App/store';
import {
  loadAuthorizedListSuccess,
  loadChosenAuthority,
} from '@main/Home/Header/headerSlices';
import { resetShareHolder } from '@main/ShareHolder/shareholderSlices';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { delay } from '@utils/delay';
import { dotReplace } from '@utils/dotReplace';
import { TFunction } from 'react-i18next';
import { handleResetSessionStore } from './Form';

interface AuthState {
  user: any;
  loading: boolean;
  loggedIn: boolean;
  error: any;
}

const token = localStorage.getItem(ACCESS_TOKEN);
const initialState: AuthState = {
  user: token ? { token } : null,
  loading: false,
  loggedIn: !!token,
  error: false,
};
const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset(state) {
      state.loading = false;
      state.error = null;
    },
    loginStart(state) {
      state.loading = true;
      state.loggedIn = false;
    },
    loginSuccess(state, { payload }: PayloadAction<LoginResult>) {
      state.loading = false;
      state.error = null;
      state.user = payload;
      state.loggedIn = true;
    },
    loginFailed(state, { payload }: PayloadAction<string>) {
      state.loading = false;
      state.error = payload;
    },
    logoutRequest(state) {
      state.loggedIn = false;
      state.user = false;
    },
  },
});

export const {
  loginStart,
  logoutRequest,
  loginSuccess,
  loginFailed,
  reset,
} = auth.actions;

export const login = <T extends LoginType>(
  t: TFunction<string>,
  type: T,
  request: LoginRequest<T>,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  try {
    dispatch(loginStart());
    const { data } = await delay(100, () => callLogin(type, request));
    if (data.success) {
      onSuccess?.();
      localStorage.setItem(ACCESS_TOKEN, data.token);
    }
    dispatch(loginSuccess(data));
    dispatch(onRefetch());
    handleResetSessionStore();
  } catch (e) {
    dispatch(loginFailed(t(dotReplace((e as any)?.message))));
    setTimeout(() => {
      dispatch(loginFailed(''));
    }, 3000);
  }
};

export const logout = (): AppThunk => (dispatch) => {
  localStorage.removeItem(ACCESS_TOKEN);
  dispatch(logoutRequest());
  dispatch(resetShareHolder());
  dispatch(loadChosenAuthority(null));
  dispatch(loadAuthorizedListSuccess([]));
};
export const selectLoading = createSelector(
  (state: RootState) => state.auth,
  (state: AuthState) => state.loading
);
export const selectError = createSelector(
  (state: RootState) => state.auth,
  (state: AuthState) => state.error
);

export const selectLoggedIn = createSelector(
  (state: RootState) => state.auth,
  (state: AuthState) => state.loggedIn
);

export const selectAuthToken = (state: RootState): string =>
  state.auth.user?.token;
export default auth.reducer;
