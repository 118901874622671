import React from 'react';
import styled from 'styled-components';
import Spinner from '@components/Spinner';

interface Props {
  onFinish: () => void;
}

export default function Countdown({ onFinish }: Props) {
  const [cd, setCd] = React.useState<{
    min: number | null;
    sec: number | null;
  }>({
    min: null,
    sec: null,
  });

  const ref = React.useRef<any>(null);
  const storedCd = sessionStorage.getItem('otp_cd');
  const then = new Date(Number(storedCd));

  const counting = () => {
    const now = new Date();
    const sec = Math.floor((then.getTime() - now.getTime()) / 1000);
    if (sec > 0) {
      setCd({
        ...cd,
        sec,
      });
    } else {
      clearInterval(ref.current);
      onFinish();
    }
  };

  React.useEffect(() => {
    if (then) {
      if (ref.current) clearInterval(ref.current);
      const interval = setInterval(counting, 1000);
      ref.current = interval;
    }

    return () => clearInterval(ref.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (cd.sec !== null) {
    return <SCountdown>{cd.sec}s</SCountdown>;
  }

  return <Spinner />;
}

const SCountdown = styled.div`
  font-size: 18px;
  line-height: 18px;
  color: #ff6c6c;
  font-family: 'rb-500';
`;
