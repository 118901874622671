import { LOCALES, TKeyOfLocale } from './locales';

export function format(n: number | undefined, decimal = 0, groupLength = 3) {
  if (!n) {
    return n;
  }

  var re = '\\d(?=(\\d{' + groupLength + '})+' + (n > 0 ? '\\.' : '$') + ')';
  const result = n.toFixed(decimal || 1).replace(new RegExp(re, 'g'), '$&,');

  if (decimal === 0) {
    return result.slice(0, -2);
  }
  return result;
}

export function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode?.removeChild(outer);

  return scrollbarWidth;
}

export const formatNumber = (val: number, lang = 'vi') => {
  const locale = LOCALES[lang as TKeyOfLocale].locale;
  return new Intl.NumberFormat(locale).format(val);
};
