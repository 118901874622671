import { CloseButton } from '@components/ModalContent';
import useOnEscapeKeyDown from '@hooks/onEscapeKeyDown';
import useOnOutsideClick from '@hooks/onOutsideClick';
import { Times } from '@styled-icons/fa-solid';
import { getScrollbarWidth } from '@utils/number';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import {
  ClickableOverlay,
  ScrollOverlay,
  StyledModal,
  Variant,
} from './Styles';

export type ModalProps = {
  className?: string;
  variant?: Variant;
  width?: number;
  withCloseIcon?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  renderLink?: FunctionComponent<{ open?: () => void }>;
  renderContent: FunctionComponent<{ close: () => void }>;
};

export const Modal: FunctionComponent<ModalProps> = ({
  className,
  variant,
  width,
  withCloseIcon,
  isOpen: propsIsOpen,
  onClose: tellParentToClose,
  renderLink,
  renderContent,
}) => {
  const [stateIsOpen, setStateOpen] = useState(false);
  const isControlled = typeof propsIsOpen === 'boolean';
  const isOpen = isControlled ? !!propsIsOpen : stateIsOpen;

  const $modalRef = useRef<HTMLDivElement>(null);
  const $clickableOverlayRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<number>();

  const closeModal = useCallback(() => {
    setIsActive(false);

    clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      if (!isControlled) {
        setStateOpen(false);
      } else {
        tellParentToClose?.();
      }
    }, 200);
  }, [isControlled, tellParentToClose]);

  useOnOutsideClick($modalRef, isOpen, closeModal, $clickableOverlayRef);
  useOnEscapeKeyDown(isOpen, closeModal);

  const [isActive, setIsActive] = useState(false);

  useLayoutEffect(() => {
    let timer: number;
    let scrollbarWidth: number;
    if (isOpen) {
      scrollbarWidth = getScrollbarWidth();
      document.body.style.overflow = 'hidden';

      if (scrollbarWidth) {
        document.body.style.marginRight = `${scrollbarWidth}px`;
      }

      window.setTimeout(() => setIsActive(isOpen), 0);
    }
    return () => {
      if (!isOpen) {
        return;
      }

      if (timer) {
        clearTimeout(timer);
      }
      clearTimeout(timerRef.current);
      timerRef.current = undefined;

      if (scrollbarWidth) {
        document.body.style.marginRight = '';
      }

      if (($root?.children.length || 0) <= 1) {
        const body = document.querySelector('body');
        if (body) {
          document.body.style.overflow  = 'auto';
        }
      }
    };
  }, [isOpen]);

  return (
    <Fragment>
      {!isControlled && renderLink?.({ open: () => setStateOpen(true) })}

      {isOpen &&
        ReactDOM.createPortal(
          <ScrollOverlay active={isActive}>
            <ClickableOverlay variant={variant!} ref={$clickableOverlayRef}>
              <StyledModal
                className={`${className || ''} ${isActive && 'active'}`}
                variant={variant!}
                active={isActive}
                width={width}
                ref={$modalRef}
              >
                {withCloseIcon && (
                  <CloseButton onClick={closeModal}>
                    <Times color="#FF5576" size={24} />
                  </CloseButton>
                )}
                {renderContent({ close: closeModal })}
              </StyledModal>
            </ClickableOverlay>
          </ScrollOverlay>,
          $root!
        )}
    </Fragment>
  );
};

Modal.defaultProps = {
  variant: 'default',
  width: 600,
  withCloseIcon: true,
};

const $root = document.getElementById('modal-root');
