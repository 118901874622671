import authReducer from '@main/Login/authSlices';
import shareholderReducer from '@main/ShareHolder/shareholderSlices';
import topicReducer from '@main/ShareHolder/topicSlices';
import headerReducer from '@main/Home/Header/headerSlices';
import { combineReducers } from '@reduxjs/toolkit';
import globalReducer from './globalSlices';

const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  shareholder: shareholderReducer,
  topic: topicReducer,
  header: headerReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
