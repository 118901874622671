import { color } from '@utils/styles';
import styled from 'styled-components';

export const Base = styled.button`
  background: ${color.primary};
  border-radius: 3px;
  color: #fff;
  padding: 6px 20px;
  min-width: 120px;
  margin-left: 12px;
  font-weight: 400;
`;

export type ButtonProps = Partial<{
  type: string;
  color: string;
  backgroundColor: string;
  mt: string;
  mb: string;
  ml: string;
  mr: string;
  px: string;
  pl: string;
  pr: string;
  minW: string;
  shadow: string;
  border: string;
  display: string;
  upper: boolean;
  fontWeight?: string | number;
}>;
const Button = styled.button<ButtonProps>`
  display: ${(p) => p.display};
  font-weight: ${(p) => p.fontWeight};
  text-align: center;
  line-height: inherit;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  ${(prop) => prop.border && `border: ${prop.border}`};
  padding: 8px 20px;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:disabled {
    cursor: not-allowed;
    background-color: #4d4d4d !important;
    border: none !important;
    color: white !important;
    box-shadow: unset;
  }

  ${(p) => p.color && `color: ${p.color};`}
  ${(p) => p.backgroundColor && `background-color: ${p.backgroundColor};`}
  ${(p) => p.mt && `margin-top: ${p.mt};`}
  ${(p) => p.mb && `margin-bottom: ${p.mb};`}
  ${(p) => p.mr && `margin-right: ${p.mr};`}
  ${(p) => p.ml && `margin-left: ${p.ml};`}
  ${(p) => (p.px || p.pl) && `padding-left: ${p.px};`}
  ${(p) => (p.px || p.pr) && `padding-right: ${p.px};`}
  ${(p) => p.minW && `min-width: ${p.minW};`}
  ${(p) => p.shadow && `box-shadow: ${p.shadow};`}
  ${(p) => p.upper && `text-transform: uppercase;`}
`;
Button.defaultProps = {
  type: 'button',
  color: '#ffffff',
  backgroundColor: color.primary,
  display: 'inline-block',
  fontWeight: 400,
};
Button.displayName = 'Button';

export default Button;
