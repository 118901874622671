import { Times } from '@styled-icons/fa-solid';
import { color, mixin, zIndexValues } from '@utils/styles';
import styled, { css } from 'styled-components';

export type Variant = 'aside' | 'center' | 'default';
export const ScrollOverlay = styled.div<{ active: boolean }>`
  z-index: ${zIndexValues.modal};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  ${mixin.scrollableY}
`;

export const ClickableOverlay = styled.div<{ variant: Variant }>`
  min-height: 100%;
  background: rgba(0, 0, 0, 0.5);

  ${(props) => clickOverlayStyles[props.variant]}
`;

const clickOverlayStyles = {
  default: css`
    padding: 0 15px;
    display: flex;
    align-items: start;
    justify-content: center;
  `,
  center: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
  `,
  aside: '',
};

export const StyledModal = styled.div<{
  variant: Variant;
  width?: number;
  active?: boolean;
}>`
  display: inline-block;
  position: relative;
  width: 100%;
  background: #fff;
  ${(props) => modalStyles[props.variant]}

  ${(p) => `
  transform: ${p.active ? 'scale(1)' : 'scale(0)'};
  transition: ${
    p.active ? 'transform 0.25s ease-in 0ms' : 'transform 0.25s ease-out 0ms'
  };
  `}
  padding: 43px 56px;
  @media(max-width: 991px) {
    padding: 20px;
  }
`;

const modalStyles = {
  default: css<{ width?: number }>`
    max-width: ${(props) => props.width}px;
    vertical-align: middle;
    margin-top: 50px;
    margin-bottom: 50px;
  `,
  center: css<{ width?: number }>`
    max-width: ${(props) => props.width}px;
    vertical-align: middle;
    ${mixin.boxShadowMedium}
  `,
  aside: css<{ width?: number }>`
    min-height: 100vh;
    max-width: ${(props) => props.width}px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  `,
};

export const CloseIcon = styled(Times)<{ variant: Variant }>`
  position: absolute;
  font-size: 25px;
  color: ${color.textMedium};
  transition: all 0.1s;
  ${mixin.clickable}
  ${(props) => closeIconStyles[props.variant]}
`;

const commonIcon = css`
  top: 10px;
  right: 12px;
  padding: 3px 5px 0px 5px;
  border-radius: 4px;
  &:hover {
    background: ${color.backgroundLight};
  }
`;
const closeIconStyles = {
  default: commonIcon,
  center: commonIcon,
  aside: css`
    top: 10px;
    right: -30px;
    width: 50px;
    height: 50px;
    padding-top: 10px;
    border-radius: 3px;
    text-align: center;
    background: #fff;
    border: 1px solid #dfe1e6;
    ${mixin.boxShadowMedium};
    &:hover {
      color: ${color.primary};
    }
  `,
};
