import { getOtp } from '@api/login';
import { Role } from '@api/types';
import { Alert } from '@components/Alert';
import Button from '@components/Button';
import ErrMessage from '@components/ErrorText';
import TextWithWrapper from '@components/InputWithIcon/Text';
import { useConfirmModal } from '@components/Modal/ConfirmModal';
import { VALIDATE } from '@constants/common';
import { zeroWidthTrim } from '@utils/zeroWidthTrim';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaLock, FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import OTPModal, { OTPModalImperativeHandle } from './OTPModal';
import { login, selectError, selectLoading } from './authSlices';
// import { dotReplace } from '@utils/dotReplace';

export enum ELoginType {
  PASSWORD = 'password',
  OTP = 'otp',
}

export default function Form({ role }: { role: Role }) {
  const methods = useForm({
    shouldUnregister: true,
  });
  const [forgetDisplay, setForgetDisplay] = useState<boolean>(false);
  const configInfo = useSelector((state: any) => state.global.config);
  useEffect(() => {
    if (configInfo?.forgotPassword === true) setForgetDisplay(true);
  }, [configInfo]);
  const { handleSubmit, register, errors, setValue, watch } = methods;

  const loading = useSelector(selectLoading);
  const loginError = useSelector(selectError);

  const history = useHistory();

  const { t } = useTranslation();
  const dp = useDispatch();

  const [idNumber, setIdNumber] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    sessionStorage.getItem('phone') || ''
  );

  const refOTPModal = React.useRef<OTPModalImperativeHandle>(null);

  const watchIdNum = watch('identityNumber');

  // onSubmit
  const onSubmit = (data: any) => {
    handleLogin(data);
  };

  // submit login
  const handleLogin = (data: any) => {
    dp(
      login(
        t,
        ELoginType.PASSWORD,
        {
          ...data,
          identityNumber: zeroWidthTrim(data.identityNumber),
          isGuest: role === Role.SHAREHOLDER ? false : true,
        },
        handleResetSessionStore
      )
    );
  };

  useEffect(() => {
    if (loginError) {
      Alert({ name: loginError, icon: 'error' });
    }
  }, [loginError]);

  const handleGetOTP = (idNumber: string) => {
    const prevIdNumer = sessionStorage.getItem('id_num') || '';
    if (!sessionStorage.getItem('otp_cd') || idNumber !== prevIdNumer) {
      getOtp({ identityNumber: idNumber })
        .then((res) => {
          setPhoneNumber(res.data.data);
          onSuccessGetOTP(res.data.data, true);
          sessionStorage.setItem('id_num', idNumber);
          sessionStorage.setItem('phone', res.data.data);
        })
        .catch((err) => {
          err?.message &&
            Alert({
              name: t((err?.message).replaceAll('.', '')),
              icon: 'error',
            });
        });
    } else {
      onSuccessGetOTP(phoneNumber);
    }
  };

  const showPhoneNumber =
    phoneNumber.slice(0, phoneNumber.length - 5) +
    '***' +
    phoneNumber.slice(-2);

  const [showModal] = useConfirmModal({
    title: t('Đăng nhập cho Cổ đông') as string,
    desc: (
      <OTPModal ref={refOTPModal} {...{ idNumber }} resendOTP={handleGetOTP} />
    ),
    hideBackButton: true,
    hideAcceptButton: true,
    subtitle: `${t('Số điện thoại nhận OTP')} ${showPhoneNumber}`,
  });

  const onSuccessGetOTP = (phoneNumber: string, resetCounting = false) => {
    if (resetCounting) {
      sessionStorage.setItem(
        'otp_cd',
        (new Date().getTime() + 60000).toString()
      );
    }
    setPhoneNumber(phoneNumber);
    showModal();
    refOTPModal.current?.onResetCounting();
  };

  React.useEffect(() => {
    setValue('password', '');
  }, [setValue]);

  // go back
  const onGoBack = () => {
    history.goBack();
    setIdNumber(null);
  };

  const onGetOTP = () => {
    setIdNumber(watchIdNum);
    handleGetOTP(watchIdNum);
  };

  return (
    <FormProvider {...methods}>
      <div className="form-login">
        <div className="desc">
          {t(
            role === Role.SHAREHOLDER
              ? 'Đăng nhập cho Cổ đông'
              : 'Đăng nhập cho Khách mời'
          )}
        </div>

        <form className="content" onSubmit={handleSubmit(onSubmit)}>
          <div className="login-item">
            <TextWithWrapper
              inputProps={{ name: 'identityNumber' }}
              register={register({
                required: t(VALIDATE.REQUIRED) as string,
              })}
              wrapperProps={{
                label: t(
                  role === Role.SHAREHOLDER
                    ? 'input.id_number'
                    : 'input.id_number_2'
                ),
                icon: <FaUser />,
                className: 'custom-input',
              }}
            />
            <ErrMessage errors={errors} name="identityNumber" />
          </div>
          <div className="login-item">
            <TextWithWrapper
              inputProps={{
                name: 'password',
                type: 'password',
              }}
              wrapperProps={{
                icon: <FaLock />,
                label: t(
                  role === Role.SHAREHOLDER
                    ? 'input.password'
                    : 'input.password_2'
                ),
                className: 'custom-input',
              }}
              register={register({
                required: t(VALIDATE.REQUIRED) as string,
              })}
            />
            <ErrMessage errors={errors} name="password" />
          </div>
          <div className="login-button">
            <Button type="submit" disabled={loading}>
              <svg
                width="19"
                height="24"
                viewBox="0 0 19 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.71427 13.5V10.5H-1.71661e-05V7.5H5.71427V4.5L9.99998 7.875V4.5L15.7143 1.5H4.2857V6H2.85713V0H18.5714V19.5L9.99998 24V19.5H2.85713V12H4.2857V18H9.99998V10.125L5.71427 13.5Z"
                  fill="white"
                />
              </svg>
              {t('Đăng nhập')}
            </Button>
          </div>
          <div className="tools">
            <span onClick={onGoBack} className="go-back">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 4.05899V1.49999C7.99968 1.30237 7.94087 1.10926 7.83098 0.945006C7.72109 0.780752 7.56504 0.6527 7.3825 0.576987C7.19996 0.501275 6.99909 0.481289 6.8052 0.51955C6.61132 0.557811 6.4331 0.652606 6.293 0.791989L0 6.99999L6.293 13.207C6.38565 13.3002 6.49581 13.3741 6.61715 13.4246C6.73848 13.475 6.86859 13.501 7 13.501C7.13141 13.501 7.26152 13.475 7.38285 13.4246C7.50419 13.3741 7.61435 13.3002 7.707 13.207C7.79991 13.1142 7.87361 13.004 7.92389 12.8827C7.97416 12.7613 8.00003 12.6313 8 12.5V10.011C10.75 10.079 13.755 10.577 16 14V13C16 8.36699 12.5 4.55699 8 4.05899Z"
                  fill="#718098"
                />
              </svg>
              {t('Quay lại')}
            </span>
            {watchIdNum &&
              role === Role.SHAREHOLDER &&
              forgetDisplay === true && (
                <div className="forgot-password">
                  <span onClick={onGetOTP}>{t('Quên mật khẩu?')}</span>
                </div>
              )}
          </div>
        </form>
      </div>
    </FormProvider>
  );
}

export const handleResetSessionStore = () => {
  sessionStorage.removeItem('otp_cd');
  sessionStorage.removeItem('id_num');
  sessionStorage.removeItem('phone');
};
