import axios from 'axios';
import { handleError, withAuthorization } from './base';
import { VoteRequest, VoteSelectRequest, VoteStatus } from './types';

export async function createVote(votes: VoteRequest[]) {
  try {
    const url = `/api/vote/create`;
    return await axios.post<any>(
      url,
      {
        votes,
      },
      {
        headers: withAuthorization(),
      }
    );
  } catch (error) {
    throw handleError(error);
  }
}

export async function createVoteSelect(votes: VoteSelectRequest[]) {
  try {
    const url = `/api/vote/select/create`;
    return await axios.post<any>(
      url,
      { votes },
      {
        headers: withAuthorization(),
      }
    );
  } catch (error) {
    throw handleError(error);
  }
}

export async function getVoteStatus({ id }: { id: string }) {
  try {
    return await axios.get<VoteStatus>(`/api/shareholder/vote/status/${id}`, {
      headers: withAuthorization(),
    });
  } catch (error) {
    const e = handleError(error);
    e.status = (error as any)?.response?.status;
    throw e;
  }
}
export async function getVotePerId(id:any) {
  try {
    return await axios.get<VoteStatus>(`/api/vote/progress?id=${id}`, {
      headers: withAuthorization(),
    });
  } catch (error) {
    const e = handleError(error);
    e.status = (error as any)?.response?.status;
    throw e;
  }
}
