import Button from '@components/Button';
import {
  ModalContent,
  ModalDescription,
  ModalTitle,
  ModalWrapper,
} from '@components/ModalContent';
import Spinner from '@components/Spinner';
import { color } from '@utils/styles';
import React, {
  ComponentProps,
  FunctionComponent,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { useModal } from 'react-modal-hook';
import styled from 'styled-components';
import { Modal } from '.';
import { useTranslation } from 'react-i18next';

export type ConfirmModalProps = {
  title: ReactNode;
  confirmBody: ReactNode;
  acceptButton?: (close: () => void) => ReactNode;
  cancelButton?: (close: () => void) => ReactNode;
  onAccept?: (close: () => void) => void;
  hideBackButton?: boolean;
  hideAcceptButton?: boolean;
  subtitle?: string;
} & Omit<ComponentProps<typeof Modal>, 'renderContent'>;

const StyledButtons = styled.div`
  text-align: center;
  padding-top: 56px;
  margin-bottom: -8px;
  @media (max-width: 991px) {
    padding-top: 40px;
  }
  button {
    margin: 0 8px 8px;
  }
`;

const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({
  title,
  confirmBody,
  acceptButton,
  cancelButton,
  onAccept,
  hideBackButton,
  hideAcceptButton,
  subtitle,
  ...others
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      {...others}
      renderContent={({ close }) => (
        <ModalWrapper>
          <ModalTitle>
            <h3>{title}</h3>
            {subtitle && <p className="subtitle">{subtitle}</p>}
          </ModalTitle>

          <ModalContent>{confirmBody}</ModalContent>

          <StyledButtons>
            {!hideBackButton &&
              (cancelButton ? (
                cancelButton(close)
              ) : (
                <Button
                  display="inline-block"
                  backgroundColor="#dc3545"
                  minW="160px"
                  color="white"
                  onClick={close}
                  fontWeight={700}
                >
                  {t('Quay lại')}
                </Button>
              ))}
            {!hideAcceptButton &&
              (acceptButton ? (
                acceptButton(close)
              ) : (
                <Button
                  backgroundColor={color.secondary}
                  color="#fff"
                  ml="6px"
                  minW="160px"
                  onClick={() => onAccept?.(close)}
                  fontWeight={700}
                >
                  {t('Đồng ý')}
                </Button>
              ))}
          </StyledButtons>
        </ModalWrapper>
      )}
    />
  );
};

ConfirmModal.defaultProps = {
  withCloseIcon: true,
  width: 736,
};

type WithLoadingButtopProps = {
  onClick: (setLoading: (loading: boolean) => void) => void;
  disabled?: boolean;
};
export const WithLoadingButton: FunctionComponent<WithLoadingButtopProps> = ({
  onClick,
  children,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);

  const handleOnClick = useCallback(() => {
    onClick(setLoading);
  }, [setLoading, onClick]);

  return (
    <Button
      backgroundColor={color.secondary}
      color="#fff"
      ml="6px"
      minW="120px"
      onClick={handleOnClick}
      disabled={disabled || loading}
    >
      {children}
      <Spinner size={14} active={loading} />
    </Button>
  );
};

export type MessageModalParams = {
  title: ReactNode;
  desc: ReactNode;
  onAccept?: () => void;
  onClose?: () => void;
  acceptButton?: ConfirmModalProps['acceptButton'];
  hideBackButton?: boolean;
  hideAcceptButton?: boolean;
  subtitle?: string;
};
export const useConfirmModal = ({
  title,
  desc,
  onAccept,
  onClose,
  acceptButton,
  hideBackButton,
  hideAcceptButton,
  subtitle,
}: MessageModalParams) => {
  const [showConfirm, hideModal] = useModal(
    () => (
      <ConfirmModal
        {...{ hideAcceptButton, hideBackButton, subtitle }}
        isOpen={true}
        onClose={() => {
          hideModal();
          onClose?.();
        }}
        onAccept={(close) => {
          close();
          onAccept?.();
        }}
        acceptButton={acceptButton}
        title={title}
        confirmBody={<ModalDescription as="div">{desc}</ModalDescription>}
      />
    ),
    [title, desc]
  );

  return [showConfirm];
};

export default ConfirmModal;
