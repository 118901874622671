import Button from '@components/Button';
import ErrMessage from '@components/ErrorText';
import TextWithWrapper from '@components/InputWithIcon/Text';
import { VALIDATE } from '@constants/common';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaMobile } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { login } from './authSlices';
import Countdown from './Countdown';
import { ELoginType, handleResetSessionStore } from './Form';
import { SOTPForm } from './Styles';

interface Props {
  idNumber: string;
  resendOTP: (idNumber: string) => void;
}

export interface OTPModalImperativeHandle {
  onResetCounting: () => void;
}

const OTPModal = React.forwardRef<OTPModalImperativeHandle, Props>(
  ({ idNumber, resendOTP }, ref) => {
    const { register, errors, handleSubmit } = useForm();

    const { t } = useTranslation();

    const dp = useDispatch();

    const [isCounting, setIsCounting] = React.useState(
      typeof idNumber === 'string'
    );

    const onFinishCounting = () => {
      setIsCounting(false);
      handleResetSessionStore();
    };

    const onResendOTP = () => {
      resendOTP(idNumber);
    };

    const onSubmit = (data: any) => {
      dp(login(t, ELoginType.OTP, { ...data, identityNumber: idNumber }));
    };

    React.useImperativeHandle(ref, () => ({
      onResetCounting: () => setIsCounting(true)
    }))

    return (
      <SOTPForm onSubmit={handleSubmit(onSubmit)}>
        <div className="login-item otp">
          <TextWithWrapper
            inputProps={{
              name: 'otp',
              autoComplete: 'off',
              type: 'password',
              pattern: "[0-9]*",
              inputMode: "numeric"
            }}
            wrapperProps={{
              icon: <FaMobile />,
              label: 'OTP',
              className: 'custom-input',
              labelLow: '19px',
              labelHigh: '10px',
            }}
            register={register({
              required: t(VALIDATE.REQUIRED) as string,
            })}
          />
          <ErrMessage errors={errors} name="otp" />
          {isCounting && (
            <div className="otp__show">
              <Countdown onFinish={onFinishCounting} />
            </div>
          )}
        </div>
        {!isCounting && (
          <div className="otp__handle">
            <div className="otp__handle-not-receive" onClick={onResendOTP}>
              {t('Không nhận được OTP?')}
            </div>
            <div onClick={onResendOTP} className="otp__handle-resend">
              {t('Gửi lại OTP')}
            </div>
          </div>
        )}
        <div className="group-button">
          <Button type="submit">{t('Đăng nhập')}</Button>
        </div>
      </SOTPForm>
    );
  }
);

export default OTPModal;
